<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="dark"
      @click="toggleTheme"
    >
      {{ dark ? 'mdi-weather-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
export default {
  setup() {
    return {
    }
  },

  data: () => ({
    dark: false,
  }),

  mounted() {
    const dark = JSON.parse(localStorage.getItem('dark'))
    if (dark) {
      this.$vuetify.theme.dark = true
      this.dark = true
    }
  },

  methods: {
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.dark = !this.dark
      const dark = localStorage.setItem('dark', this.dark)
    }
  }
}
</script>

<style>
</style>
