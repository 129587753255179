<template>
  <v-app>
    <snack-bar ref='snackbar' />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from '@/components/SnackBar.vue'

export default {
  components: {
    SnackBar,
  },
  mounted() {
    this.$root.snackbar = this.$refs.snackbar
  },
}
</script>

<style>
</style>
