<template>
  <v-progress-linear
    v-if="showProgressBar"
    indeterminate
    :color="color"
    class="loading-progress"
  ></v-progress-linear>
</template>

<style lang="scss">
.loading-progress {
  position: fixed !important;
  z-index: 9000;
}
</style>

<script>
export default {
  name: 'ProgressBar',
  data: () => ({
    showProgressBar: false,
    color: 'primary',
  }),
  methods: {
    show(data) {
      if (data) {
        this.color = data.color || 'primary'
      }
      this.showProgressBar = true
    },
    hide() {
      this.showProgressBar = false
    }
  },
}
</script>