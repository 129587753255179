<template>
  <v-app>
    <progress-bar ref="progressbar" />
    <snack-bar ref="snackbar" />
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen" />

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen" />
          <!-- <v-text-field
            rounded
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            class="app-bar-search flex-grow-0"
            hide-details
          /> -->

          <v-spacer />

          <!-- Right Content -->
          <v-btn color="primary" class="mr-4" @click="toggleAutoSuspend">
            Auto suspend is&nbsp;
            <span v-if="autoSuspend"> On </span>
            <span v-else> Off </span>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mr-4" v-bind="attrs" v-on="on"> Timezone {{ selectedTimezone }} </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in timezoneOptions" :key="i" @click="selectTimezone(item.value)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <theme-switcher />

          <!-- Notification -->
          <!-- <v-menu
            offset-y
            left
            origin="top right"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="ms-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-bell-outline</v-icon>
              </v-btn>
            </template>
            <div
              v-show="items.length > 1"
              class="v-list-item justify-end"
            >
              <v-btn
                small
                @click="clear(null)"
              >
                clear
              </v-btn>
            </div>
            <v-list
              v-if="items.length"
              dense
            >
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                :class="getPrintNotifcationClass(item.days)"
                @click="goTo(item)"
              >
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
                <v-btn
                  icon
                  small
                  @click.stop="clear(item)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item-title class="mx-3">
                No new notication
              </v-list-item-title>
            </v-list>
          </v-menu> -->

          <!-- Account Settings -->
          <app-bar-user-menu />
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-2">
        <slot />
      </div>
    </v-main>

    <v-idle @idle="onidle" @remind="onremind" :loop="true" :reminders="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 30, 45, 60]"
      :wait="5" :duration="3600" />
    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> &copy; 2021 </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import SnackBar from '@/components/SnackBar.vue'
import ProgressBar from '@/components/ProgressBar.vue'

import { mapActions } from 'vuex'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    SnackBar,
    ProgressBar,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
    }
  },
  data: () => ({
    autoSuspend: false,
    items: [
      { id: 1, title: 'Go To Accounts', to: 'accounts' },
      { id: 2, title: 'Go To Dashboard', to: 'dashboard' },
      { id: 3, title: 'Go To Labels', to: 'labels' },
      { id: 4, title: 'You have not printed any labels for 30 days', days: 30 },
      { id: 5, title: 'You have not printed any labels for 60 days', days: 60 },
      { id: 6, title: 'You have not printed any labels for 90 days', days: 90 },
    ],
    selectedTimezone: null,
    timezoneOptions: [
      { text: 'UTC+0', value: null },
      { text: 'EST', value: 'America/New_York' },
      { text: 'CST', value: 'America/Chicago' },
      { text: 'MST', value: 'America/Denver' },
      { text: 'PST', value: 'America/Los_Angeles' },
    ],
  }),
  async mounted() {
    this.$root.snackbar = this.$refs.snackbar
    this.$root.progressbar = this.$refs.progressbar

    const tz = JSON.parse(localStorage.getItem('tz'))
    const selectedTz = this.timezoneOptions.find(i => i.value == tz)
    if (selectedTz) {
      this.selectedTimezone = selectedTz.text
    }
    const url = process.env.VUE_APP_API_URL
    const token = this.$store.state.token
    const { data: suspend } = await axios.get(`${url}/api/auto-suspend`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    this.autoSuspend = suspend
  },
  methods: {
    ...mapActions({
      removeToken: 'logout',
      setTz: 'setTz',
    }),

    async toggleAutoSuspend() {
      const suspend = !this.autoSuspend ? 'true' : null

      const url = process.env.VUE_APP_API_URL
      const token = this.$store.state.token
      await axios.post(
        `${url}/api/auto-suspend`,
        { suspend },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      this.autoSuspend = !this.autoSuspend
    },
    getPrintNotifcationClass(days) {
      if (days) {
        if (days >= 30 && days < 60) {
          return 'yellow black--text'
        } else if (days >= 60 && days < 90) {
          return 'warning white--text'
        } else {
          return 'error white--text'
        }
      }
    },
    goTo(item) {
      this.updateNotification(item)
      if (item.to) {
        if (this.$route.name !== item.to || this.$route.path !== item.to) {
          this.$router.push({ name: item.to })
        }
      }
    },
    clear(item) {
      if (item) {
        this.updateNotification(item)
      } else {
        this.items = []
      }
    },
    updateNotification(item) {
      let editedIndex = this.items.indexOf(item)
      this.items.splice(editedIndex, 1)

      // TODO: send data to notification api
    },
    async onidle() {
      this.$refs.snackbar.show({ message: 'You have been logged out', color: 'error' })
      const url = process.env.VUE_APP_API_URL
      const { data } = await axios.delete(`${url}/logout`)
      this.removeToken()
      this.$router.push({ name: 'login' })
      alert('You have been logged out')
    },
    onremind(time) {
      let duration = 3000
      if (time <= 10) {
        duration = 10000
      }
      this.$refs.snackbar.show({ message: `You'll be logout in ${time} seconds`, color: 'error', timer: duration })
    },

    selectTimezone(val) {
      this.setTz(val)
      const selectedTz = this.timezoneOptions.find(i => i.value == val)
      if (selectedTz) {
        this.selectedTimezone = selectedTz.text
      }
      this.$root.$emit('updateData')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  margin-left: auto;
  margin-right: auto;
}
</style>
