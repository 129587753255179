import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import moment from 'moment-timezone'

// --------------------- V Idle ---------------------

import Vidle from 'v-idle'
Vue.use(Vidle)

// --------------------- V Idle ---------------------

// --------------------- V Mask ---------------------

import VueMask from 'v-mask'
Vue.use(VueMask);

// --------------------- V Mask ---------------------

// --------------------- Filters ---------------------

Vue.filter('readableDate', (d, tz=null, format="lll") => {
  if (d) {
    // const dDate = moment(d).tz(tz)
    // console.log("tz:", tz);
    // console.log("original date:", d);
    // console.log("date:", dDate);
    const dDate = tz ? moment.utc(d).tz(tz) : moment.utc(d)
    if(dDate && dDate.isValid()) return dDate.format(format)
  }
  return 'No Date'
})

Vue.filter('ago', (d, tz=null) => {
  if (d) {
    const dDate = tz ? moment.utc(d).tz(tz) : moment.utc(d)
    if(dDate && dDate.isValid()) return dDate.fromNow()
  }
  return 'No Date'
})

Vue.filter('truncate', (value, size=45, clamp="...") => {
  if (!value) return ''
  value = value.toString()
  if (value.length <= size) return value
  return value.substr(0, size) + clamp
})

Vue.filter('titleCase', (text, split=' ') => {
  const sentence = text.toLowerCase().split(split)
  for (let i = 0; i < sentence.length; i += 1) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
});

// change new line to <br>
Vue.filter('nl2br', (text) => {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
});

// --------------------- Filters ---------------------

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
