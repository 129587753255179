import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import preset from './default-preset/preset'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    // iconfont: 'mdiSvg',
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        grey: '#e6e6e6',
      },
      dark: {
        grey: '#25213a',
      },
    }
  },
})
