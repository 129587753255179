<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        :to="{ name: 'dashboard' }"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="70px"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        />
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">
            Grandshipper
          </h2>
        </v-slide-x-transition> -->
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-for="(link, i) in links"
        :key="i"
        :title="link.title"
        :to="{ name: link.url }"
        :icon="link.icon"
        exact
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
// import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    // NavMenuSectionTitle,
    // NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      links: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard',
          url: 'dashboard',
        },
        {
          title: 'Labels',
          icon: 'mdi-file-document-multiple',
          url: 'labels',
        },
        {
          title: 'Accounts',
          icon: 'mdi-account-multiple',
          url: 'accounts',
        },
        {
          title: 'USPS Reps',
          icon: 'mdi-account-cog',
          url: 'usps',
        },
        {
          title: 'Affiliates',
          icon: 'mdi-card-account-mail',
          url: 'affiliate',
        },
        {
          title: 'Deactivated Accounts',
          icon: 'mdi-account-lock',
          url: 'accounts-deactivated',
        }
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
