import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import('@/views/pages/labels/Labels.vue'),
  },
  {
    path: '/labels/create',
    name: 'labels-create',
    component: () => import('@/views/pages/labels/LabelsCreate.vue'),
  },
  {
    path: '/labels/:id',
    name: 'labels-show',
    component: () => import('@/views/pages/labels/LabelsShow.vue'),
  },
  {
    path: '/labels/:id/edit',
    name: 'labels-edit',
    component: () => import('@/views/pages/labels/LabelsEdit.vue'),
  },
  {
    path: '/accounts/deactivated',
    name: 'accounts-deactivated',
    component: () => import('@/views/pages/deactivated-accounts/DeactivateAccounts.vue'),
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('@/views/pages/accounts/Accounts.vue'),
  },
  {
    path: '/accounts/create',
    name: 'accounts-create',
    component: () => import('@/views/pages/accounts/AccountsCreate.vue'),
  },
  {
    path: '/accounts/:id/edit',
    name: 'accounts-edit',
    component: () => import('@/views/pages/accounts/AccountsEdit.vue'),
  },
  {
    path: '/accounts/:id/change-password',
    name: 'accounts-change-password',
    component: () => import('@/views/pages/accounts/AccountsPasswordChange.vue'),
  },
  {
    path: '/accounts/:id',
    name: 'accounts-show',
    component: () => import('@/views/pages/accounts/AccountsShow.vue'),
  },
  {
    path: '/usps',
    name: 'usps',
    component: () => import('@/views/pages/usps/Usps.vue'),
  },
  {
    path: '/usps/create',
    name: 'usps-create',
    component: () => import('@/views/pages/usps/UspsCreate.vue'),
  },
  {
    path: '/usps/:id/edit',
    name: 'usps-edit',
    component: () => import('@/views/pages/usps/UspsEdit.vue'),
  },
  {
    path: '/usps/:id',
    name: 'usps-show',
    component: () => import('@/views/pages/usps/UspsShow.vue'),
  },
  {
    path: '/usps/:id/change-password',
    name: 'usps-change-password',
    component: () => import('@/views/pages/accounts/AccountsPasswordChange.vue'),
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import('@/views/pages/pb/PB.vue'),
  },
  {
    path: '/affiliate/create',
    name: 'affiliate-create',
    component: () => import('@/views/pages/pb/PBCreate.vue'),
  },
  {
    path: '/affiliate/:id/edit',
    name: 'affiliate-edit',
    component: () => import('@/views/pages/pb/PBEdit.vue'),
  },
  {
    path: '/affiliate/:id',
    name: 'affiliate-show',
    component: () => import('@/views/pages/pb/PBShow.vue'),
  },
  {
    path: '/affiliate/:id/change-password',
    name: 'affiliate-change-password',
    component: () => import('@/views/pages/accounts/AccountsPasswordChange.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
