import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') ?? '',
    tz: localStorage.getItem('tz') ?? null,
  },
  getters: {
    getToken: (state) => () => {
      return state.token
    },
    getTz: (state) => () => {
      return JSON.parse(state.tz)
    },
  },
  mutations: {
    login (state, payload) {
      localStorage.setItem('token', payload)
      state.token = payload
    },
    logout (state) {
      localStorage.removeItem('token')
      state.token = ''
    },
    setTz (state, payload) {
      localStorage.setItem('tz', JSON.stringify(payload))
      state.tz = JSON.stringify(payload)
    }
  },
  actions: {
    login (context, payload) {
      context.commit('login', payload)
    },
    logout (context) {
      context.commit('logout')
    },
    setTz (context, payload) {
      context.commit('setTz', payload)
    },
  },
  modules: {

  },
})
